.app {
  margin-top: 15%;
  text-align: center;
}

a,
a:visited {
  color: #872fff;
}

.links {
  padding: 2.5em;
}

.links a {
  padding: 0.75em;
}

.links a:focus,
.links a:hover {
  filter: invert(0.5) sepia(1) hue-rotate(210deg) saturate(10) brightness(77%);
}

@media (prefers-color-scheme: dark) {
  body {
    color: #ffffff;
    background-color: #171a1c;
  }

  .links a {
    filter: invert(1);
  }
}
